<template>
  <b-card no-body class="business-card">
    <div class="">{{ $t("acting_as_a_buyer") }} </div>

    <div class="business-items">
      <div class="business-item">
        <div class="d-flex align-items-center justify-content-between">
          <b-form-checkbox
            v-model="allow"
            @change="(allow = true), (decline = false), submit()"
          >
            {{ $t("yes") }}
          </b-form-checkbox>
          <b-badge variant="light-success"> {{ $t("allow") }} </b-badge>
        </div>
      </div>
      <div class="business-item">
        <div class="d-flex align-items-center justify-content-between">
          <b-form-checkbox
            v-model="decline"
            @change="(allow = false), (decline = true), submit()"
          >
            {{ $t("no") }}
          </b-form-checkbox>
          <b-badge variant="light-primary"> {{ $t("deny") }} </b-badge>
        </div>
      </div>
    </div>

    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-primary"
      block
      @click="submit"
    >
      {{ $t("save") }}
    </b-button> -->
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import i18n from "@/libs/i18n";
export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      allow: false,
      decline: false,
    };
  },
  props: ["buyer"],
  created() {

      if (this.buyer == 0) {
        this.allow = false;
        this.decline = true;
      }

      if (this.buyer == 1) {
        this.allow = true;
        this.decline = false;
      }

  },
  computed: {},
  watch: {
    buyer(oldValue) {
      if (oldValue == 0) {
        this.allow = false;
        this.decline = true;
      }

      if (oldValue == 1) {
        this.allow = true;
        this.decline = false;
      }


    },
  },
  methods: {
    checkIfBuyer() {},
    submit(type) {
      this.$http
        .post(`/profile/admin/update/buyer/${this.$route.params.id}`, {
          buyer: this.allow ? 1 : 0,
        })
        .then((res) => {
          if (res) {
            let message = "";
            if (this.allow) {
              message = i18n.t("acting_as_a_buyer_allowed");
            } else {
              message = i18n.t("acting_as_a_buyer_denied");
            }
            // this.$emit('actingAsBuyer', this.allow)
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: message,
            //     icon: "SuccessIcon",
            //     variant: "success",
            //   },
            // });
          }
        });
    },
  },
};
</script>
