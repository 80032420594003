<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="9">
          <h2 class="text-blue font-weight-bold col-md-12">
            {{ $t("tasks") }}
          </h2>
        </b-col>
 
      </b-row>

      <b-table show-empty large :fields="fields" :items="items" responsive="sm">
        <template #cell(id)="data">
          <span  style="width:55px;" class="badge badge-light-primary">
            <span> {{ data.item.id }} </span>
          </span>
        </template>
        <template #cell(company)="data">
          <b-link @click="handleTaskClick(data.item)">
            <span>{{ data.item.company.name }} </span>
          </b-link>
        </template>
        <template #cell(status)="data">
          <span v-if="data.value == 0">{{ $t("pending") }} </span>
          <span v-if="data.value == 1">{{ $t("approved") }}</span>
          <span v-if="data.value == 2">{{ $t("declined") }}</span>
                <span v-if="data.value == 4">{{ $t("partially_approved") }}</span>
          <span v-if="data.value == 3">{{ $t("completed") }}</span>
        </template>
        <template #cell(title)="data">
          <b-button
            @click="downloadPdf(data.item.id)"
            variant="outline-success"
            >{{ data.value }}</b-button
          >
        </template>
        <template #empty="scope">
          <div>
            {{ scope.emptyText }}
          </div>
        </template>
      </b-table>
      <CustomLoader :show="isLoading" />
      <!-- <b-row m-0 p-0>
        <b-col cols="12" class="text-center pt-2">
          <b-pagination
            v-model="page"
            :disabled="isLoading"
            :total-rows="rows"
            @input="getApiData()"
            :per-page="itemsPerPage"
            align="center"
          ></b-pagination>
        </b-col>
      </b-row> -->

      <todo-task-handler-sidebar
        v-if="isTaskHandlerSidebarActive"
        @approveModalTask="approveModalTask"
        v-model="isTaskHandlerSidebarActive"
        :task="task"
        :isTaskHandlerSidebarActive="isTaskHandlerSidebarActive"
        :task_id="task_id"
        :clear-task-data="clearTaskData"
        @remove-task="removeTask"
        @add-task="addTask"
        @closeSideBar="closeSideBar"
      />
    </b-card>
  </div>
</template>

<script>
import ApproveModal from "@/components/Common/ApproveModal";
import fileDownload from "js-file-download";
import Ripple from "vue-ripple-directive";
import TodoTaskHandlerSidebar from "./Tasks/TaskDetails.vue";

import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
  BTh,
  BTr,
  BBadge,
  BCard,
} from "bootstrap-vue";
import CustomLoader from "@/components/Common/CustomLoader";
import Task from "@/components/Company/Task";

export default {
  name: "Tasks",
  components: {
    ApproveModal,
    Task,
    CustomLoader,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BTr,
    BTh,
    BButton,
    BLink,
    BBadge,
    BCard,
    TodoTaskHandlerSidebar,
  },
  directives: {
    Ripple,
  },
  computed: {
    rows() {
      return this.totalItems;
    },
  },

  data() {
    return {
      task: null,
      task_id: "",
      company_id: this.$route.params.id,
      modalText: null,
      modalTitle: null,
      showInput: null,
      options: null,
      fields: [
        { label: "ID", align: "start", key: "id", sortable: true },
        { label: "Company", align: "start", key: "company", sortable: true },
        { label: "Title", align: "start", key: "name", sortable: true },
        { label: "Status", key: "status", customSlot: true, sortable: false },
      ],
      items: [],
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      status: [],
      isLoading: false,
      totalItems: 0,
      approveModalAction: null,
      isTaskHandlerSidebarActive: false,
      blankTask: "",
    };
  },
  props: ["makeCall"],
  watch: {
    isTaskHandlerSidebarActive(newValue, oldValue) {
      if (newValue == false) {
        this.getApiData();
      }
    },
  },
  created() {
    this.getApiData();
  },

  methods: {
    closeSideBar() {
      this.isTaskHandlerSidebarActive = false;
    },
    showSuccessMessage(message) {
      let text = this.$t(message);
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    approveModalTask(task) {
      if (task.name == "approve") {
        this.changeStatus(task.id, "approve");
      } else if (task.name == "complete") {
        this.changeStatus(task.id, "complete");
      } else if (task.name == "hideTask") {
        this.hideTask(task.id);
      } else {
        this.changeStatus(task.id, "decline");
      }
      this.idToChange = task.id;
    },
    hideTask(id) {
      this.$http
        .post("company/admin/makeVisibleCookpitTask/" + this.$route.params.id)
        .then((res) => {
          if (res) {
            this.getApiData();
            this.showSuccessMessage("Task Successfully updated");
          }
        });
    },
    handleTaskClick(taskData) {
      this.task = taskData;
      this.task_id = taskData.id;
      this.isTaskHandlerSidebarActive = true;
    },
    clearTaskData() {
      this.task = JSON.parse(JSON.stringify(this.blankTask));
    },
    removeTask() {},
    addTask() {},
    updateTask() {},
    changeModalInfo(action, title, text, id) {
      this.approveModalAction = action;
      this.modalText = text;
      this.modalTitle = title;
      this.idToChange = id;
    },
    // callApproveModalAction() {
    //   if (this.approveModalAction == "approve") {
    //     this.changeStatus(this.idToChange, "approve");
    //   }
    //   if (this.approveModalAction == "decline") {
    //     this.changeStatus(this.idToChange, "decline");
    //   }
    //   if (this.approveModalAction == "delete") {
    //     this.changeStatus(this.idToChange, "delete");
    //   }
    //   if (this.approveModalAction == "complete") {
    //     this.changeStatus(this.idToChange, "complete");
    //   }
    // },
    changeStatus(id, status) {
      this.isLoading = true;
      let s = 0;
      if (status == "approve") {
        s = 1;
      }
      if (status == "decline") {
        s = 2;
      }
      if (status == "complete") {
        s = 3;
      }
      if (status == "delete") {
        s = 99;
      }
      this.$http
        .post("/task/admin/update/" + id, {
          status: s,
          comment: this.showInput,
        })
        .then((response) => {
          this.showSuccessMessage("Task Successfully updated");
          this.getApiData();
          this.isLoading = false;
          this.isTaskHandlerSidebarActive = false;
          this.$emit("updateData", "tasks");
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    downloadPdf(id) {
      this.isLoading = true;
      this.$http
        .post(
          "/task/admin/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, "doc.pdf");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    getApiData() {
      this.isLoading = true;
      this.items = [];
      this.$http
        .post("/task/admin/company/" + this.company_id + "/list", {
          status: this.status,
          options: null,
          filters: this.filters,
          search: this.search,
        })
        .then((response) => {
          if (response.data.status == 200) {
            this.items = response.data.value.data;
            this.totalItems = response.data.value.total;
            this.page = response.data.value.current_page;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
