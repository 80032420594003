<template>
  <b-card>
    <b-col>
      <h4> {{$t('internal_scoirng_2b')}}</h4>
    </b-col>
    <b-col col="4">
      <b-form-group
        name="ht-blocking-condition-a"
        id="ht-blocking-condition-a"
        :label="$t('code-block-A') + ':'"
        label-for="blocking-a"
      >
        <validation-provider
          #default="{ errors }"
          name="HT-Blocking Condition a"
          rules="required"
        >
          <b-form-select
            id="blocking-a"
            :options="scoreOptions"
            v-model="condition_a"
          >
          </b-form-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col col="4">
      <b-form-group
        name="ht-blocking-condition-b"
        id="ht-blocking-condition-b"
        :label="$t('code-block-B') + ':'"
        label-for="blocking-b"
      >
        <validation-provider
          #default="{ errors }"
          name="HT-Blocking Condition b"
          rules="required"
        >
          <b-form-select
            id="blocking-b"
            :options="scoreOptions"
            v-model="condition_b"
          >
          </b-form-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col col="4">
      <b-form-group
        name="ht-blocking-condition-c"
        id="ht-blocking-condition-c"
        :label="$t('code-block-C') + ':'"
        label-for="blocking-c"
      >
        <validation-provider
          #default="{ errors }"
          name="HT-Blocking Condition c"
          rules="required"
        >
          <b-form-select
            id="blocking-c"
            :options="scoreOptions"
            v-model="condition_c"
          >
          </b-form-select>

          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        name="ht-score"
        id="ht-score"
        :label="$t('HT-Score') + ':'"
        label-for="ht-score-input"
      >
        <validation-provider
          #default="{ errors }"
          name="HT-Score"
          rules="required"
        >
          <b-form-input id="ht-score-input" v-model="score"> </b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group
        name="ht-pod"
        id="ht-pod"
        :label="$t('HT-POD') + ':'"
        label-for="ht-pod-input"
      >
        <validation-provider
          #default="{ errors }"
          name="HT-POD"
          rules="required"
        >
          <b-form-input id="ht-pod-input" v-model="pod"> </b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col class="justify-content-end d-flex aling-items-end" aling="end">
      <b-button @click="submit">{{ $t("test") }}</b-button>
    </b-col>
  </b-card>
</template>
<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      condition_a: 0,
      condition_b: 0,
      condition_c: 0,
      score: "",
      pod: "",
      scoreOptions: [0, 1],
    };
  },
  props: ["id"],
  methods: {
    submit() {
      let data = {
        condition_a: this.condition_a,
        condition_b: this.condition_b,
        condition_c: this.condition_c,
        score: this.score,
        pod: this.pod,
        company_id: this.id,
      };
      this.$http.post("/company/admin/scoring/s2b", data).then((res) => {
        let text = this.$t("Scoring performed!");
        this.$swal({
          position: "center",
          icon: "success",
          title: text,
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        }).then((res) => {
          this.$emit("getAll");
        });
      });
    },
  },
};
</script>
