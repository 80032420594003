<template>
  <div>
    <div v-if="!load">
      <ScoringAlert
        v-if="companyData != null && nextStepText"
        :scoring="companyData.scoring"
        :data="companyData"
        @showRate="showRate"
        :role="role"
        :nextStepText="nextStepText"
        :nextStep="nextStep"
        :nextStepStatus="nextStepStatus"
        @getNextStep="checkNextStep"
        @completeTask="completeTask"
      />
      <CustomLoader v-if="company == null" @showRate="showRate" />

      <b-tabs content-class="pt-1" fill v-model="tabIndex">
        <b-tab @click="getData('company')">
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>{{ $t("company") }}</span>
          </template>
          <b-card>
            <CompanyUpdateForm
              :makeCall="company"
              @actingAsBuyer="actingAsBuyer"
              @updateData="getCompany"
              
            />
          </b-card>
        </b-tab>
   
        <b-tab @click="getData('invoice')">
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>{{ $t("invoices") }}</span>
          </template>
          <Invoces
            v-if="tabIndex == 1"
            :makeCall="invoice"
            @updateData="getCompany"
          ></Invoces>
        </b-tab>
        <b-tab @click="getData('rates')">
          <template #title>
            <feather-icon icon="StarIcon" />
            <span>{{ $t("scoring") }}</span>
          </template>

          <Scoring
            v-if="tabIndex == 2"
            :makeCall="rate"
            :nextStepText="nextStepText"
            :checkStep="checkStep"
            @updateData="getCompany"
            :companyData="companyData"
          />
        </b-tab>
        <b-tab @click="getData('tasks')">
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>{{ $t("tasks") }}</span>
          </template>
          <Tasks
            v-if="tabIndex == 3"
            :makeCall="tasks"
            @updateData="getCompany"
          />
        </b-tab>
        <b-tab @click="getData('documents')">
          <template #title>
            <feather-icon icon="FileIcon" />
            <span>{{ $t("documents") }}</span>
          </template>
          <b-card>
            <Documents
              v-if="tabIndex == 4"
              :makeCall="documents"
              :data="companyData"
            ></Documents>
          </b-card>
        </b-tab>
        <b-tab @click="getData('transactions')">
          <template #title>
            <feather-icon icon="CreditCardIcon" />
            <span>{{ $t("transactions") }}</span>
          </template>
          <b-card>
            <Transactions
              v-if="tabIndex == 5"
              :data="companyData"
              :makeCall="transactions"
              :company="companyData"
            ></Transactions>
          </b-card>
        </b-tab>
        <b-tab @click="getData('additional_info')">
          <template #title>
            <feather-icon icon="InfoIcon" />
            <span>{{ $t("additional_info") }}</span>
          </template>
          <div>
            <AdditionalInfo
              v-if="tabIndex == 6"
              :data="companyData"
              :makeCall="additional_info"
              :company="companyData"
              @redirectToScoring="redirectToScoring"
            ></AdditionalInfo>
          </div>
        </b-tab>
        <b-tab @click="getData('contact')">
          <template #title>
            <feather-icon icon="UserIcon" />
            <span>{{ $t("contact") }}</span>
          </template>
          <b-card>
            <CompanyContact
              :makeCall="company"
              @actingAsBuyer="actingAsBuyer"
              @updateData="getCompany"
               :companyData="companyData"
            />
          </b-card>
        </b-tab>
      </b-tabs>
    </div>
    <div v-else>
      <img src="/loader.svg" />
    </div>
  </div>
</template>

<script>
import CompanyUpdateForm from "@/components/Company/CompanyUpdateForm";
import { BTabs, BTab, BCardText, BCard, BAlert } from "bootstrap-vue";
import Tasks from "@/components/Company/Tasks";
import ScoringAlert from "@/components/Company/ScoringAlert";
import CustomLoader from "@/components/Common/CustomLoader";
import Documents from "@/components/Company/Documents/Documents.vue";
import Scoring from "@/views/scoring/Scoring.vue";
import CompanyModal from "@/components/Company/Invoices/CompanyModal.vue";
import Invoces from "@/components/Company/Invoices/Invoices.vue";
import Transactions from "@/components/Company/Transactions/Transactions.vue";
import AdditionalInfo from "@/components/Company/AdditionaInfo/AdditionalInfo.vue";
import CompanyContact from "@/components/Contact/CompanyContacts.vue"
export default {
  name: "CompanyData",
  components: {
    CustomLoader,
    ScoringAlert,
    Tasks,
    CompanyUpdateForm,
    BCardText,
    BTabs,
    BTab,
    BCard,
    BAlert,
    CompanyModal,
    Documents,
    Scoring,
    Invoces,
    Transactions,
    AdditionalInfo,
    CompanyContact
  },
  data() {
    return {
      companyData: null,
      company: false,
      tabIndex: 0,
      invoice: false,
      documents: false,
      tasks: false,
      rate: false,
      url: "",
      dialog: false,
      updateData: false,
      invoiceId: "",
      singleFile: "",
      selectedFile: "",
      nextStepText: "",
      role: 0,
      load: true,
      nextStep: null,
      nextStepStatus: 0,
      checkStep: false,
      transactions: false,
      contact: false,
    };
  },
  methods: {
    loadData() {
      this.getCompany();
    },
    actingAsBuyer(data) {
      this.role = data;
    },
    updateProp() {
      this.updateData = false;
    },
    getAll() {
      this.updateData = true;
    },
    closeModal() {
      this.dialog = false;
    },
    showModal(item) {
      this.invoiceId = item.id;
      this.singleFile = item;
      if (this.singleFile) {
        this.dialog = true;
      }
    },
    showRate() {
      this.tabIndex = 2;
    },
    redirectToScoring() {
      this.tabIndex = 2;
    },
    getCompany(tab) {
        this.$http
        .post(`/company/admin/show/${this.$route.params.id}`)
        .then((res) => {
          if (res && res.data && res.data.value) {
            this.getNextStep();
            this.companyData = res.data.value;
            this.$forceUpdate();

            if (tab && tab == "scoring") {
              this.tabIndex = 2;
              this.completeTask();
            }
            if (tab && tab == "company") {
              this.tabIndex = 0;
              this.completeTask();
            }

            if (tab && tab == "invoice") {
              this.tabIndex = 1;
              this.completeTask();
            }
            if (tab && tab == "documents") {
              this.tabIndex = 4;
              this.completeTask();
            }
            if (tab && tab == "tasks") {
              this.tabIndex = 3;
              this.completeTask();
            }

            if (tab && tab == "additional_info") {
              this.tabIndex = 6;
              this.completeTask();
            }
            if (tab && tab == "contact") {
              console.log('tab'.tab)
              this.tabIndex = 7;
              this.completeTask();
            }
          }
        });
    },
    completeTask() {
      if (this.nextStepText.id) {
        this.$http
          .post(`/nextStep/changeStatus/${this.nextStep.id}`)
          .caatch((err) => {
            this.load = false;
          })
          .then((res) => {
            this.nextStep = res.data.value;
            if (this.nextStep.tab == "Documents") {
              this.tabIndex = 4;
            }
            if (this.nextStep.tab == "Task") {
              this.tabIndex = 3;
            }
            if (this.nextStep.tab == "Scoring") {
              this.tabIndex = 2;
            }
            if (this.nextStep.tab == "Invoices") {
              this.tabIndex = 1;
            }
            if (this.nextStep.tab == "Company") {
              this.tabIndex = 0;
            }

            if (res.data.message) {
              this.nextStepText = res.data.message;
            }

            // this.getCompany();
          });
      }
    },
    checkNextStep() {
      this.$http.post(`/nextStep/show/${this.$route.params.id}`).then((res) => {
        if (res.data.value) {
          this.nextStep = res.data.value;
          this.nextStepText = res.data.value.title;
          if (this.nextStep.tab == "Documents") {
            this.tabIndex = 4;
          }
          if (this.nextStep.tab == "Task") {
            this.tabIndex = 3;
          }
          if (this.nextStep.tab == "Scoring") {
            this.tabIndex = 2;
          }
          if (this.nextStep.tab == "Invoices") {
            this.tabIndex = 1;
          }
          if (this.nextStep.tab == "Company") {
            this.tabIndex = 0;
          }
        }

        if (res.data.message) {
          this.nextStepText = res.data.message;
        }
        this.checkStep = true;
      });
    },
    getNextStep() {
      this.load = true;
      this.$http
        .post(`/nextStep/show/${this.$route.params.id}`)
        .then((res) => {
          if (res.data.value) {
            this.nextStep = res.data.value;
            this.nextStepText = res.data.value.title;
          } else if (res.data.message) {
            this.nextStepText = res.data.message;
          }
          this.nextStepStatus = res.data.status;
          this.load = false;
        })
        .catch((err) => {
          this.load = false;
        });
    },
    getData(type) {
      console.log('type,',type)
      this.invoice = false;
      this.documents = false;
      this.tasks = false;
      this.rate = false;
      this.company = false;
      this.additional_info = false;
      this.contact = false;
      if (type == "company") {
        this.company = true;
      } else if (type == "tasks") {
        this.tasks = true;
      } else if (type == "rates") {
        this.rate = true;
      } else if (type == "invoice") {
        this.invoice = true;
        this.checkStep = false;
      } else if (type == "documents") {
        this.documents = true;
      } else if (type == "transactions") {
        this.transactions = true;
      } else if (type == "additional_info") {
        this.additional_info = true;
      }
      else if (type == "contact") {
        this.contact = true;
      }
    },
  },
  created() {
    if (typeof this.$route.params.id == "undefined") {
      this.$router.push("/companies");
    }
    if(this.$route.hash && /#invoices\d+/.test(this.$route.hash)){
      this.tabIndex = 1;
    }
    this.getCompany();
  },
};
</script>
