<template>
  <b-card>
    <b-row class="">
      <b-col class="col-md-12">
        <h2
          class="text-blue font-weight-bold col-md-10 col-lg-10 col-xl-11 mt-1"
        >
          {{ $t("additional_documents") }}
        </h2>
      </b-col>
    </b-row>

    <Table
      :url="url"
      :body="{}"
      :updateData="makeCall"
      :showSearch="false"
      :page="'documents'"
      :fields="fields"
      show
      :actionButton="data.debtor.length < 1 ? button : false"
      @actionButtonTab="actionButtonTab"
    >
      <template #cell(company)="data">
        <span>{{ data.item.company.name }} </span>
      </template>
      <template #cell(id)="data">
        <span style="width:55px;" class="badge badge-light-primary">
          <span> {{ data.value }} </span>
        </span>
      </template>
      <template #cell(status)="data">
        <span v-if="data.value == 0">{{ $t("pending") }} </span>
        <span v-if="data.value == 1">{{ $t("approved") }}</span>
        <span v-if="data.value == 2">{{ $t("declined") }}</span>
        <span class="text-secondary" v-if="data.value == 3">{{
          $t("proof_of_ownership_is_in_progress")
        }}</span>
      </template>
      <template #cell(title)="data">
        {{ data.value }}
      </template>
      <template #cell(custom)="data">
<!--        <b-button-->
<!--          class="mr-1 mobile"-->
<!--          @click="downloadPdf(data.item.id, data.item)"-->
<!--          variant="outline-success"-->
<!--          size="sm"-->
<!--        >-->
<!--          <FeatherIcon icon="DownloadIcon"></FeatherIcon>-->
<!--        </b-button>-->
        <b-button
            v-b-tooltip.hover :title="$t('read_pdf')"
          class="mr-1 web"
          @click="readPdf(data.item.id, data.item)"
          variant="outline-success"
          size="sm"
        >
          <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
        </b-button>
      </template>
    </Table>

    <b-modal
      v-model="dialog"
      centered
      hide-footer
      :title="$t('request_additionl_docs')"
    >
      <b-row>
        <b-col class="mt-2 mb-4">
          <b-form-textarea
            v-model="message"
            rows="14"
            :placeholder="$t('message')"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-md-12" align="end">
          <b-button variant="primary" @click="hideTask">{{
            $t("request")
          }}</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>
<script>
import Table from "@/components/Common/Table2.vue";
import fileDownload from "js-file-download";
import { BButton } from 'bootstrap-vue'
export default {
  components: { BButton, Table },
  data() {
    return {
      loader: false,
      url: "",
      dialog: false,
      message: "",
      fields1: [
        { label: "title", align: "start", key: "title", sortable: false },
        { label: "", customSlot: false, key: "custom", class: "text-right" },
      ],
      fields: [
        { label: "id", align: "start", key: "id", sortable: true },
        { label: "company", align: "start", key: "company", sortable: false },
        { label: "title", align: "start", key: "title", sortable: false },
        {
          label: "description",
          align: "start",
          key: "description",
          sortable: false,
        },
        { label: "status", key: "status", customSlot: true, sortable: false },
        { label: "", customSlot: true, key: "custom", class: "text-right" },
      ],
      item: null,
      showPdfViwer: false,
      type: "",
      base64: "",
      source: null,
      src: "",
      embedType: "",
      button: {
        text: this.$t("request_dd_docs"),
        link: "",
        color: "primary",
      },
    };
  },
  props: ["makeCall", "data"],
  watch: {
    makeCall(newValue, oldValue) {
      if (newValue !== oldValue && this.makeCall) {
        this.loader = true;
        this.loader = false;
      }
    },
  },
  methods: {
    readPdf(id, item) {
      this.item = item;
      this.$store.commit("app/ON_SIDEBAR_CLICK", false);
      this.loadPdf = true;
      this.type = "";
      this.base64 = "";
      this.src = "";
      this.embedType = "";

      this.$http
        .post("/additional-docs/admin/read/" + id, {
          responseType: "json",
        })
        .then((response) => {
          this.base64 = response.data.value.b64;
          this.type = response.data.value.ext;
          if (this.type == "pdf") {
            this.showPdfViwer = false;
            this.src =
              "data:application/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "application/" + this.type;

            this.loadPdf = false;
            this.showPdfViwer = true;
          } else {
            this.showPdfViwer = false;
            this.src =
              "data:image/" +
              this.type +
              ";base64," +
              this.base64 +
              "#toolbar=0";
            this.embedType = "image/" + this.type;

            this.loadPdf = false;
            this.showPdfViwer = true;
          }

          this.$store.commit("app/SET_FILE_TYPE", this.type);
          this.$store.commit("app/SET_FILE_BASE64", this.base64);

          window.open("/read-files", "_blank");
        });
    },
    showSuccessMessage(message) {
      let text = this.$t(message);
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    actionButtonTab() {
      this.message = "";
      this.dialog = true;
    },
    hideTask() {
      let data = {
        message: this.message,
      };
      this.$http
        .post(
          "/company/admin/makeVisibleCookpitTask/" + this.$route.params.id,
          data
        )
        .then((res) => {
          if (res) {
            this.dialog = false;
            this.showSuccessMessage("Success");
          }
        });
    },

    downloadPdf(id, item) {
      this.$http
        .post(
          "/additional-docs/admin/download/" + id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, item.title);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.loader = true;
    this.url = `/additional-docs/admin/${this.$route.params.id}/list`;
    this.loader = false;
  },
};
</script>
